import Modal from '../Modal'
import Template from '../Template'
import * as style from './style.module.scss'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useDialogState, DialogDisclosure } from 'reakit/Dialog'

const PageBecomeASitter = (props) => {
  function SignUpModal() {
    const signUp = useDialogState({ baseId: 'signUp' })

    return (
      <>
        <DialogDisclosure className={style.buttonSubmit} {...signUp}>
          Create account
        </DialogDisclosure>
        <Modal {...signUp}>
          <div className={style.modal}>
            <div>
              <div className={style.title}>
                <h2>Join the list</h2>
                <button
                  className={style.buttonClose}
                  onClick={() => signUp.hide()}
                >
                  Close
                </button>
              </div>
              <p>
                Interested in creating a dog sitter account? Add your email
                address and we'll reach out as soon as we're live in your area
              </p>
              <form
                action='https://app.loops.so/api/newsletter-form/clmusgt0c0185l40oqj3wbd3s'
                method='post'
                onSubmit={async (event) => {
                  event.preventDefault()
                  const response = await fetch(event.target.action, {
                    method: event.target.method,
                    body: new URLSearchParams(new FormData(event.target)),
                  })

                  const result = await response.json()

                  if (result.success) {
                    navigate('/thank-you-for-signing-up')
                    return
                  }

                  alert(result)
                }}
              >
                <div>
                  <label htmlFor={'email'}>Email address</label>
                  <input type='email' name='email' required />
                  <input
                    type='hidden'
                    name='userGroup'
                    value='Waitlist signup'
                  />
                </div>
                <div>
                  <button type='submit'>Sign up for the waitlist</button>
                </div>
              </form>
            </div>
            <p className={style.note}>
              By signing up, you agree to receive emails from us. We'll only
              send you emails related to doggos.com and your account.
            </p>
          </div>
        </Modal>
      </>
    )
  }

  return (
    <Template>
      <Helmet>
        <title>Become a sitter - {props.data.site.siteMetadata.title}</title>
      </Helmet>
      <div className={style.heroContainer}>
        <h1>Join a community of caring local dog sitters</h1>
        <p className={style.subline}>
          More than 50,000 dog owners browse doggos.com every month
        </p>
        <div className={style.buttonContainer}>
          <SignUpModal />
          <a href='#platform_benefits' className={style.button}>
            Learn more
          </a>
        </div>
      </div>

      <div className={style.makeTheSwitch}>
        <div className={style.copy}>
          <h2>Make the switch to doggos.com</h2>
          <p>
            <strong>
              The only platform that is{' '}
              <span className={style.decorativeText}>completely FREE</span> to
              use for dog sitters & pet professionals
            </strong>
          </p>
          <p>
            Pet care is hard work, and we don't think it's fair to take fees out
            of your earnings... so we don't.
          </p>
          <p>
            We make money in other ways, like by offering benefits and add-ons
            for pet owners, so you can focus on your business, not managing
            software
          </p>
        </div>
        <div>
          <StaticImage
            draggable='false'
            placeholder='blurred'
            src={'./dog-sitter-1.png'}
            alt='Image of a young woman sitting on a sofa petting a dog.'
            className={style.coverPhoto}
          />
        </div>
      </div>

      <div className={style.platformBenefits}>
        <h2 id='platform_benefits'>Platform benefits</h2>

        <div className={style.textSection}>
          <div className={style.textBlockContainer}>
            <div className={style.textBlock}>
              <h3 className={style.h3}>
                All reservations are protected by doggos.com
              </h3>
              <p>
                A minimum of $5,000 veterinary care reimbursement is included
                with all reservations paid via doggos.com, even if your business
                already has insurance
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>
                <span className={style.textHighlight}>0 fees for sitters</span>,
                payouts available as soon as 24 hours
              </h3>
              <p>
                Doggos.com is free to use for sitters, with payments eligible to
                be paid out as soon as 24 hours after a completed reservation
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Free advertising</h3>
              <p>
                We advertise doggos.com in all the cities we’re in, in our
                emails, and on high-visibility pages like our search and blog
                posts, completely free to you
              </p>
            </div>
            <div className={style.textBlock}>
              <h3 className={style.h3}>Ongoing support from doggos.com</h3>
              <p>
                Our support team works directly with sitters and pet businesses
                to make sure they're getting the best out of the platform
              </p>
            </div>
            <div className={style.buttonContainer}>
              <SignUpModal />
            </div>
          </div>
        </div>
        <div className={style.platformFeatures}>
          <h1>Platform features</h1>
          <div className={style.calendar}>
            <h2>Custom calendar</h2>
            <p>
              Our reservation calendar works on any device. Easily track and
              schedule dogs, manage your availability, and set custom prices
            </p>
            <StaticImage
              draggable='false'
              placeholder='blurred'
              src={'./image1.png'}
              alt='Image of a booking calendar interface'
              className={style.image}
            />
          </div>
          <div className={style.chat}>
            <h2>Live chat</h2>
            <p>
              Accept or deny reservations easily with our chat. Communicate with
              dog owners, send video and photo updates, process payments, or
              request a meeting
            </p>
            <StaticImage
              draggable='false'
              placeholder='blurred'
              src={'./image2.png'}
              alt='Image of a chat interface'
              className={style.image}
            />
          </div>
          <div className={style.profiles}>
            <div className={style.content}>
              <h2>Dog profiles</h2>
              <p>
                Our beautiful free dog profile pages help you find dogs that are
                a perfect match for your services
              </p>
              <div className={style.buttonContainer}>
                <SignUpModal />
              </div>
            </div>
            <StaticImage
              draggable='false'
              placeholder='blurred'
              src={'./image3.png'}
              alt='Stylized images of a black German Shepherd'
              className={style.image}
            />
          </div>
          <div className={style.getStarted}>
            <h2>Get started today</h2>
            <p>
              We're always working on new features and improvements and would
              love to hear your feedback! Message @doggos in the chat anytime to
              ask questions or get help
            </p>
            <div className={style.buttonContainer}>
              <SignUpModal />
            </div>
          </div>
        </div>
      </div>
    </Template>
  )
}

export default PageBecomeASitter
