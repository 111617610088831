// extracted by mini-css-extract-plugin
export var button = "style-module--button--f592a";
export var buttonClose = "style-module--buttonClose--b9a44";
export var buttonContainer = "style-module--buttonContainer--57da8";
export var buttonSubmit = "style-module--buttonSubmit--b6da2";
export var calendar = "style-module--calendar--b756c";
export var chat = "style-module--chat--97131";
export var content = "style-module--content--b730d";
export var copy = "style-module--copy--c4ab3";
export var coverPhoto = "style-module--coverPhoto--d0b61";
export var decorativeText = "style-module--decorativeText--3914e";
export var getStarted = "style-module--getStarted--91b73";
export var h1 = "style-module--h1--ede34";
export var h2 = "style-module--h2--c086d";
export var h3 = "style-module--h3--14cb4";
export var heroContainer = "style-module--heroContainer--1facb";
export var image = "style-module--image--78aad";
export var link = "style-module--link--8afc0";
export var makeTheSwitch = "style-module--makeTheSwitch--6b557";
export var modal = "style-module--modal--f56d3";
export var note = "style-module--note--422d8";
export var platformBenefits = "style-module--platformBenefits--3648b";
export var platformFeatures = "style-module--platformFeatures--ad871";
export var profiles = "style-module--profiles--05b7c";
export var subline = "style-module--subline--23df8";
export var textBlock = "style-module--textBlock--720ce";
export var textBlockContainer = "style-module--textBlockContainer--59ed8";
export var textHighlight = "style-module--textHighlight--1df0b";
export var textSection = "style-module--textSection--928fb";
export var title = "style-module--title--1edf8";